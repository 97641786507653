module.exports.CMEditor = {
  dsn: 'https://e788f1b6d7b54d3482c54d1e27b95f38@sentry.wixpress.com/165',
}
module.exports.CMMyAccount = {
  dsn: 'https://bd7df26433634b41b7e711922156bff7@sentry.wixpress.com/166',
}
module.exports.DBSMEditor = {
  dsn: 'https://adc03f08d1814b5a835f586aaba9d8ff@sentry.wixpress.com/159',
}
module.exports.DBSMViewer = {
  dsn: 'https://27180ecd50484e4eafe543b40d29866d@sentry.wixpress.com/89',
}
module.exports.UserErrors = {
  dsn: 'https://73a0410004ae41b7b60ca1c4b4684996@sentry.wixpress.com/183',
}
module.exports.WixData = {
  dsn: 'https://9653fbb3e48143d890dba8a09a5a98c6@sentry.wixpress.com/184',
}
module.exports.DBSMEditorNext = {
  dsn: 'https://fb8f6b9f1e9a47c78a1b8b92bebbae3d@sentry-next.wixpress.com/926',
}
